import { axiosGet, axiosErrors, axiosPost, axiosDelete, axiosPut } from '../helpers/axios';
import { setIsShowErrorMessagePortfolio } from '../slices/portfolioSlice';

export const loadCurrenciesAsync = () => (dispatch) => {
  const loadCurrencies = axiosGet('dictionaries/currencies');
  return loadCurrencies()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadCashTransactionTypesAsync = () => (dispatch) => {
  const loadCashTransactionTypes = axiosGet('dictionaries/cash_transaction_types');
  return loadCashTransactionTypes()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadSharesTransactionTypesAsync = () => (dispatch) => {
  const loadSharesTransactionTypes = axiosGet('dictionaries/shares_transaction_types');
  return loadSharesTransactionTypes()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadTaxRegimeTypesAsync = () => (dispatch) => {
  const loadTaxRegimeTypes = axiosGet('dictionaries/tax_regime_types');
  return loadTaxRegimeTypes()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const loadPortfoliosAsync =
  (index, limit, sort, options = { isNeedSpinner: true }) =>
  (dispatch) => {
    const loadPortfolios = axiosGet(
      `portfolio/?index=${index || 0}&limit=${limit || 10}${
        sort ? `&order=${sort.column}&order=${sort.direction}` : ''
      }`,
      options
    );
    return loadPortfolios()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
        throw axiosErrors(e);
      });
  };

export const loadPortfolioByIdAsync = (id) => (dispatch) => {
  const loadPortfolioById = axiosGet(`portfolio/${id}`);
  return loadPortfolioById()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadPortfolioDiagramAsync =
  (id, datePeriod, options = { isNeedSpinner: false }) =>
  () => {
    const loadPortfolioDiagram = axiosGet(
      `portfolio/${id}/total${datePeriod ? `?startDate=${datePeriod[0]}&endDate=${datePeriod[1]}` : ''}`,
      options
    );
    return loadPortfolioDiagram()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const loadPortfolioPerformanceAsync =
  (id, datePeriod, options = { isNeedSpinner: false }) =>
  () => {
    const loadPriceChart = axiosGet(
      `portfolio/${id}/price-chart${datePeriod ? `?startDate=${datePeriod[0]}&endDate=${datePeriod[1]}` : ''}`,
      options
    );
    return loadPriceChart()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const createPortfolioAsync = (newPortfolio) => (dispatch) => {
  const createPortfolio = axiosPost(`portfolio`);
  return createPortfolio({
    name: newPortfolio.name,
    createdAt: newPortfolio.createdAt,
    currencyId: parseFloat(newPortfolio.currencyId)
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const deletePortfolioAsync = (id) => (dispatch) => {
  const deletePortfolio = axiosDelete(`portfolio/${id}`);
  return deletePortfolio()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updatePortfolioAsync = (id, updatedPortfolio) => (dispatch) => {
  const updatePortfolio = axiosPut(`portfolio/${id}`);
  return updatePortfolio({ ...updatedPortfolio })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updatePortfolioPerfAsync = (id) => (dispatch) => {
  const updatePortfolio = axiosGet(`mgapi/portfolio/${id}/update`);
  return updatePortfolio()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadPortfolioTickersAsync = (id, index, limit, currentYear, sort) => (dispatch) => {
  const loadPortfolioTickers = axiosGet(
    `portfolio/${id}/tickers?index=${index || 0}&limit=${limit || 10}&currentYear=${currentYear}${
      sort ? generateSortString(sort) : ''
    }`
  );
  return loadPortfolioTickers()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadPortfolioCashListAsync = (id, index, limit, sort, filters) => (dispatch) => {
  const loadPortfolioCashList = axiosPost(
    `portfolio/${id}/cash_list?index=${index || 0}&limit=${limit || 10}${sort ? generateSortString(sort) : ''}`
  );
  return loadPortfolioCashList({
    transactionDate: filters.transactionDate,
    transactionType: filters.transactionType
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadPortfolioSharesListAsync = (id, index, limit, sort, filters) => (dispatch) => {
  const loadPortfolioSharesList = axiosPost(
    `portfolio/${id}/operation_list?index=${index || 0}&limit=${limit || 10}${sort ? generateSortString(sort) : ''}`
  );
  return loadPortfolioSharesList({
    executionDate: filters.executionDate,
    tickerId: filters.tickerName,
    transactionType: filters.transactionType,
    transactionDate: filters.transactionDate,
    amount: filters.amount
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadPortfolioTaxAsync = (id) => (dispatch) => {
  const loadPortfolioTax = axiosGet(`portfolio/${id}/tax_regime`);
  return loadPortfolioTax()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

const generateSortString = (sort) => {
  return sort.reduce((result, next, index) => {
    return (result += `&order[${index}]=${next.column}&order[${index}]=${next.direction}`);
  }, '');
};

export const deletePortfolioTickerAsync = (id, tickerId) => (dispatch) => {
  const deletePortfolioTicker = axiosDelete(`portfolio/${id}/tickers/${tickerId}`);
  return deletePortfolioTicker()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const deletePortfolioCashAsync = (id, cashId) => (dispatch) => {
  const deletePortfolioCash = axiosDelete(`portfolio/${id}/cash/${cashId}`);
  return deletePortfolioCash()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const deletePortfolioSharesAsync = (id, operId) => (dispatch) => {
  const deletePortfolioShares = axiosDelete(`portfolio/${id}/operation/${operId}`);
  return deletePortfolioShares()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const deletePortfolioTaxAsync = (id, taxId) => (dispatch) => {
  const deletePortfolioTax = axiosDelete(`portfolio/${id}/tax_regime/${taxId}`);
  return deletePortfolioTax()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updatePortfolioTickerAsync = (id, tickerId, updatedTicker) => (dispatch) => {
  const updatePortfolioTicker = axiosPut(`portfolio/${id}/tickers/${tickerId}`);
  return updatePortfolioTicker(updatedTicker)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updatePortfolioCashAsync = (id, cashId, updatedCash) => (dispatch) => {
  const updatePortfolioCash = axiosPut(`portfolio/${id}/cash/${cashId}`);
  return updatePortfolioCash(updatedCash)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updatePortfolioSharesAsync = (id, operId, updatedShares) => (dispatch) => {
  const updatePortfolioShares = axiosPut(`portfolio/${id}/operation/${operId}`);
  return updatePortfolioShares(updatedShares)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const updatePortfolioTaxAsync = (id, taxId, updatedTax) => (dispatch) => {
  const updatePortfolioTax = axiosPut(`portfolio/${id}/tax_regime/${taxId}`);
  return updatePortfolioTax(updatedTax)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const createPortfolioTickerAsync = (id, newTicker) => (dispatch) => {
  const createPortfolioTicker = axiosPost(`portfolio/${id}/tickers`);
  return createPortfolioTicker({ ...newTicker })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const createPortfolioCashAsync = (id, cashData) => (dispatch) => {
  const createPortfolioCash = axiosPost(`portfolio/${id}/cash`);
  return createPortfolioCash({ ...cashData })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};
export const createPortfolioTaxAsync = (id, taxData) => (dispatch) => {
  const createPortfolioTax = axiosPost(`portfolio/${id}/tax_regime`);
  return createPortfolioTax({ ...taxData })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const createPortfolioSharesAsync = (id, sharesData) => (dispatch) => {
  const createPortfolioShares = axiosPost(`portfolio/${id}/operation`);
  return createPortfolioShares({ ...sharesData })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const loadTickersInPortfolioByCompanyNameAsync = (searchValue) => (dispatch) => {
  const loadTickersInPortfolioByCompanyName = axiosGet(
    `portfolio/tickers?index=0&limit=50&search[0]=companyName&search[0]=${searchValue}`
  );
  return loadTickersInPortfolioByCompanyName()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};

export const searchCompanyInPortfolioByNameAsync = (name) => (dispatch) => {
  const searchCompanyByName = axiosGet(`company/search?value=${name}`, {
    isNeedSpinner: false
  });
  return searchCompanyByName()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      dispatch(setIsShowErrorMessagePortfolio(axiosErrors(e)));
      throw axiosErrors(e);
    });
};
