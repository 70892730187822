import React, { useCallback, useEffect, useState } from 'react';

import { Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { calcFairValueAsync } from 'src/app/api/financial-model';
import { AppInputNumber } from 'src/app/components/form/input-number';
import { AppSlider } from 'src/app/components/form/slider';
import { roundTo } from 'src/app/helpers/strings';
import { selectUserData, selectModel, setModel, setMessage } from 'src/app/slices/financialModelSlice';

import styles from './CalculationParametersModal.module.css';

export const CalculationParameters = ({ isVisible, handleClose, handleError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const financialModel = useSelector(selectModel);
  const userData = useSelector(selectUserData);

  const [discountRate, setDiscountRate] = useState();
  const [timeEarnings, setTimeEarnings] = useState();
  const [timeBookValue, setTimeBookValue] = useState();
  const [appraisalRate, setAppraisalRate] = useState(0);

  useEffect(() => {
    setDiscountRate(roundTo(userData?.discount_rate * 100, 2));
    setTimeEarnings(userData?.appraisal_earnings);
    setTimeBookValue(userData?.appraisal_book);
    setAppraisalRate(roundTo(userData?.appraisal_rate * 100, 2));
  }, [userData?.appraisal_book, userData?.appraisal_earnings, userData?.appraisal_rate, userData?.discount_rate]);

  const handleCalculate = useCallback(() => {
    const interactions = {
      ...(discountRate && { discount_rate: discountRate / 100 }),
      ...(timeEarnings && { appraisal_earnings: timeEarnings }),
      ...(timeBookValue && { appraisal_book: timeBookValue }),
      ...(appraisalRate && { appraisal_rate: appraisalRate / 100 })
    };

    if (Object.keys(interactions).length) {
      dispatch(
        calcFairValueAsync({
          ...financialModel,
          interactions
        })
      )
        .then((model) => {
          dispatch(setModel(model));
          dispatch(setMessage(model.interactions));
        })
        .catch((err) => {
          handleError(err);
        });
    }
    handleClose();
  }, [appraisalRate, discountRate, dispatch, financialModel, handleClose, timeBookValue, timeEarnings, handleError]);

  const handleClick = () => {
    setTimeEarnings(roundTo(financialModel.service_data.avg_hist_values.AveragePEarnings, 2));
    setTimeBookValue(roundTo(financialModel.service_data.avg_hist_values.AveragePBook, 2));
  };

  const onCancel = () => {
    setTimeEarnings(userData?.appraisal_earnings);
    setTimeBookValue(userData?.appraisal_book);
    handleClose();
  };

  return (
    <Modal
      title={t('calculation_parameters_modal_title')}
      centered
      width={650}
      visible={isVisible}
      className={`mg-modal`}
      okText={t('calculate_button_label')}
      cancelText={t('cancel')}
      getContainer={'main'}
      okButtonProps={{
        className: `mg-btn ${styles.button}`
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        'data-test': 'alerts_page_cancel_alert'
      }}
      onOk={handleCalculate}
      onCancel={onCancel}
    >
      <fieldset className={styles.field}>
        <legend className={styles.legendContainer}>
          <a className={styles.legend} onClick={handleClick}>
            reset to last 4-years average
          </a>
        </legend>
        <table>
          <tbody className={styles.parametersInputWrapper}>
            <tr>
              <td>{t('terminal_share_label')}</td>
              <td>
                <Tooltip overlayClassName="mg-tooltip" title={timeEarnings}>
                  <div>
                    <AppInputNumber
                      value={timeEarnings}
                      onChange={setTimeEarnings}
                      controls={false}
                      min={0}
                      max={10000}
                    ></AppInputNumber>
                  </div>
                </Tooltip>
              </td>
              <td>{t('time_earnings_label')}</td>
            </tr>
            <tr>
              <td>{t('terminal_share_label')}</td>
              <td>
                <Tooltip overlayClassName="mg-tooltip" title={timeBookValue}>
                  <div>
                    <AppInputNumber
                      value={timeBookValue}
                      onChange={setTimeBookValue}
                      controls={false}
                      min={0}
                      max={10000}
                    ></AppInputNumber>
                  </div>
                </Tooltip>
              </td>
              <td>{t('time_book_label')}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <div className={styles.sliderWrapper}>
        <AppSlider
          min={0}
          max={100}
          showTooltip={false}
          leftLabel={t('earnings_label')}
          rightLabel={t('book_value_label')}
          value={appraisalRate}
          onChange={setAppraisalRate}
        />
        <div className={styles.appraisalRateValues}>
          <div>
            <AppInputNumber
              min={0}
              max={100}
              value={appraisalRate}
              onBlur={setAppraisalRate}
              onPressEnter={setAppraisalRate}
            ></AppInputNumber>
            <span>%</span>
          </div>
          <div>
            <AppInputNumber
              min={0}
              max={100}
              value={100 - appraisalRate}
              onBlur={(val) => setAppraisalRate(100 - val)}
              onPressEnter={(val) => setAppraisalRate(100 - val)}
            ></AppInputNumber>
            <span>%</span>
          </div>
        </div>
        <table>
          <tbody className={styles.appraisalRateValues}>
            <tr>
              <td>{t('discount_rate_label_1')}</td>
              <td>
                <Tooltip overlayClassName="mg-tooltip" title={discountRate}>
                  <div className={styles.appInputDiscountRate}>
                    <AppInputNumber
                      value={discountRate}
                      onChange={setDiscountRate}
                      min={0}
                      max={100}
                      controls={false}
                    ></AppInputNumber>
                  </div>
                </Tooltip>
              </td>
              <td> {t('discount_rate_label_2')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
